import firebase from "firebase/app"
import "firebase/database"
import "firebase/storage"
import "firebase/analytics"

if (!firebase.apps.length) {
  firebase.initializeApp({
      apiKey: "AIzaSyDkIMrfHzrGkkYEFKKojARNb6quvd21V6U",
      authDomain: "museulium-cb08f.firebaseapp.com",
      databaseURL: "https://museulium-cb08f.firebaseio.com",
      projectId: "museulium-cb08f",
      storageBucket: "museulium-cb08f.appspot.com",
      messagingSenderId: "1086047900386",
      appId: "1:1086047900386:web:e1caaf6fd269c720e804bb",
      measurementId: "G-46LBHYV6V6"
  })
  firebase.analytics();
}

export default firebase